@import 'functions';
@import 'fonts';
@import 'variables';
@import 'normalize.css';
@import '~bootstrap/scss/bootstrap.scss';


//Essentials

.row {
  margin-left: rempx(5);
  margin-right: rempx(5);
}

.col-12 {
  padding: 0;
}

body {
  margin: 0px;
  list-style: none;
  background-color: $sf-bg-dark;
  font-family: $font-family-opensans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  position: relative;
  padding-top: rempx(40);
  bottom: 0;
  width: 100%;
  height: rempx(40);            /* Footer height */
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.container {
  position: relative;
  padding: 0;
  margin: 0 auto;
  height: 100%;
  max-width: rempx(1440);
}

.header-title {
  margin-top: rempx(30);
  margin-bottom: rempx(30);
}

// Button

.btn {

  // type
  font-family: $font-family-opensans;
  font-size: rempx(12);
  color: $white;
  font-weight: 700;
  text-decoration: none;

  // btn
  background: transparent;
  padding: 8px 15px 8px 15px;

  border-color: $white-300;
  border-width: 1px;
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;

  &:hover {
    background: $white-900;
    font-weight: 700;
    text-decoration: none;
  }

}

// Nav

.navbar {
  font-family: $font-family-opensans;
  flex-wrap: inherit;
  max-width: rempx(1200);
}

.navbar-brand {
  padding-bottom: 0;
}

.opactiy-0 {
  opacity: 0;
}

.right-side-nav {
  display: flex;
}

.right-side-nav-home {
  display: flex;
}

.nav-item {
  color: $white-400;
  font-weight: 700;
  font-size: rempx(11);
  letter-spacing: rempx(2);
  opacity: 1;
  margin-right: rempx(40);

  &.active {
    font-weight: 400;
    opacity: 1;
  }
  
}

.nav-item:hover {
  color: $white-900;
}

.project-nav {
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: rempx(1000);
  margin-left: auto;
  margin-right: auto;
  margin-top: rempx(40);
  margin-bottom: rempx(150); 
}

.project-nav-home {
  display: flex;
  float: right;

  max-width: rempx(1440);
  margin-left: auto;
  margin-right: auto;
  margin-top: rempx(40);
}

// Code Snippet

.linenumber {
  color: #fff;
}

.react-syntax-highlighter-line-number {
  margin-right: rempx(10);
  color: rgb(255, 255, 255);
  text-align: left;
}

.linenumber {
  color: rgb(255, 255, 255);
}

.react-syntax-highlighter-codeblock {
  margin-right: rempx(30);
}

// Flex

.flex-parent {
  display: flex;
  width: 100%;
}

.flex-child-66 {
  width: 100%;
}

.flex-child-33 {
  width: 50%;
}

.flex-equal > * {
  flex: 1;
}

.d-md-flex {
  display: flex !important;
}

.flex-md-equal > * {
  flex: 1;
  justify-content: center;
}

.arrow-flex {
  display: flex;
  justify-content: center;
  
}

// Hero

.hero-banner {
  margin: 0;
  position: relative;
}

.center-bg {
  margin: 0 auto;
}

// Svgs in Hero

.svglines {
  width: rempx(761);
  height: 82vh;
  margin: 0 auto;
}

.svglines_small {
  display: none;
}

.namelines {
  width: rempx(376);
  height: rempx(7);
  margin: 0 auto;
}

.flurish-horizontal {
  margin-left: rempx(30);
  margin-right: rempx(12);
}

.flurish-horizontal-centered {
  margin-right: rempx(12);
}

.flurish-vertical {
  rotate: 270deg;
  margin-right: rempx(0);
  margin-top: rempx(60);

}

.flurish-parent {
  height: 100%;
}

.flurish-text-vertical {
  font-family: $font-family-opensans;
  font-weight: 700;
  font-size: rempx(11);
  letter-spacing: rempx(4);
  color: $white-300;
  margin-top: rempx(18);
  transform: rotate(270deg);
}

.flurish-quality {
  margin-left: rempx(45);
  margin-top: rempx(110);
}

.flurish-quality-google {
  margin-left: rempx(52);
  margin-top: rempx(110);
}

.flurish-quality-google-2 {
  margin-left: rempx(72);
  margin-top: rempx(140);
}

.rounded-corner {
  border-radius: rempx(6);
}

// svgs on home

.lines-box {
  height: rempx(0);
}

.bg-lines {
  position: absolute;
  margin-top: rempx(460);
  margin-left: rempx(-100);
  height: 1920px;
  opacity: 30%;
}

.arrow-style {
  margin: 0 auto;
  align-self: flex-start;
  opacity: 20%;
}

// Hero on Home

.vh {
  height: 100vh;
}

.name {
  font-family: $font-family-ailerons;
  font-weight: 300;
  font-size: rempx(30);
  color: $white;
  text-align: center;
}

@media (max-width: 428px) {

  .name {
    font-size: rempx(26);
  }

}

.name-header {
  font-family: $font-family-ailerons;
  font-weight: 800;
  font-size: rempx(16);
  color: $white;
  text-align: left;
}

.role {
  font-family: $font-family-opensans;
  font-weight: 700;
  font-size: rempx(12);
  color: $white;
  text-align: center;
  letter-spacing: rempx(2);
  color: $white-500;
}

.flurish-text {
  font-family: $font-family-opensans;
  font-weight: 700;
  font-size: rempx(11);
  letter-spacing: rempx(2);
  color: $white-500;
  margin-top: rempx(18);
}

.top-absolute {
  position: absolute;
  top: 40%;
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
}

.bot-absolute {
  position: absolute;
  top: 44%;
  margin: 0 auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 100%;
}

// Text

p {
  margin-bottom: 0;
}

h1 {
  font-family: $font-family-opensans;
  font-weight: 800;
  font-size: rempx(24);
}

h2 {
  font-family: $font-family-opensans;
  font-weight: 400;
  font-size: rempx(16);
  margin-top: inherit;
  margin-bottom: inherit;
}

h3 {
  font-family: $font-family-opensans;
  font-weight: 700;
  font-size: rempx(11);
  letter-spacing: rempx(2);
  color: $white;
}

h4 {
  font-family: $font-family-unica;
  font-size: rempx(16);
  font-weight: 300;
  color: $white-900;
}

h5 {
  font-family: $font-family-opensans;
  font-weight: 300;
  font-size: rempx(40);
}

h6 {
  font-family: $font-family-opensans;
  font-weight: 500;
  font-size: rempx(13);
  letter-spacing: rempx(2);
  color: $white-500;
}

// Home Tagline

.home-about {
  max-width: 95%;
  margin: 0 auto;
}

.home-flex {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
}

.home-flex-item {
  margin: 0 35px;
}

@media (max-width: 810px) {

  .home-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  
  .home-flex-item {
    margin: 0 15px 60px;
  }
}

@media (max-width: 428px) {

  .home-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }
  
  .home-flex-item {
    margin: 0 15px 60px;
  }
}

.home-animation-text {
  font-size: rempx(38);
  letter-spacing: rempx(3);
  text-transform: uppercase
}

@media (max-width: 428px) {
  
  .home-animation-text {
    font-size: rempx(22);
  }
}

.headline {
  display: inline-block;
  margin-left: rempx(30);
}

@media (max-width: 810px) {
  
  .headline {
    display: inline-block;
    margin-left: rempx(16);
  }

}

.hr-sm {
  border: 1px solid $white-100;
}

// Title 

.pp-ts {
  margin-right: rempx(0);
}

.resume-skill-title {
  color: $white;
}

.resume-title {
  font-family: $font-family-ailerons;
  font-weight: 800;
  font-size: rempx(24);
  color: $white-800;
  margin-right: rempx(0);
}

.pp-title {
  font-family: $font-family-unica;
  font-weight: 300;
  font-size: rempx(38);
  min-width: rempx(370);
  letter-spacing: rempx(2);
  color: $white-900;
}

.pp-sub-text {
  font-family: $font-family-opensans;
  font-weight: 700;
  font-size: rempx(11);
  letter-spacing: rempx(2);
  color: $white-300;
}

.resume-sub-text {
  font-family: $font-family-opensans;
  font-weight: 700;
  font-size: rempx(11);
  letter-spacing: rempx(2);
  color: $white-300;
}

.pp-ts-resume {
  width: 20%;
}

.resume-company {
  font-family: $font-family-opensans;
  color: $white;
  text-align: end;
  align-self: center;
  width: 10%;
  margin-right: rempx(40);

}

@media (max-width: 428px) {

  .resume-company {
    flex-flow: wrap;
    text-align: start;
    margin-right: rempx(0);
    margin-bottom: rempx(10);
    width: 80%;

  }
}

.resume-company-title {
  font-size: rempx(16);
  

}

.resume-company-year {
  font-size: rempx(14);
  font-weight: 700;
  color: $white-300;
}

.resume-skill {
  width: 70%;
  margin-bottom: rempx(20);
}

.skill-group {
  text-align: center;
}

.resume-arrow {
  max-height: rempx(26);
}

.text-xl {
  font-size: rempx(70);
  font-weight: 300;
}

.line-spacing-title {
  line-height: rempx(60);
}

.line-spacing-paragraph {
  line-height: rempx(30);
}

p {
  font-family: $font-family-opensans;
  font-size: rempx(12);
  line-height: rempx(10);
  letter-spacing: rempx(.4);
  color: $text-paragraph;
}

.project-page-p {
  text-justify: left;
  font-size: rempx(14);
  line-height: rempx(26);
  opacity: 1;
}

.project-page-mid-role {
  text-justify: left;
  margin: 0 auto;
  font-size: rempx(14);
  line-height: rempx(26);
}

.project-double-text {
  text-justify: left;
  margin: 0 auto;
  font-size: rempx(14);
  line-height: rempx(26);
}

// ---------- Flex Classes ---------- //

.flex-images-bld {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  background-color: $sf-bg-images;

}

.flex-bantam {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

}

.flex-google {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

}

.flex-images-illustrations {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: center;
  
}

.flex-resume {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
}

.flex-resume-skill {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.skills {
  margin: 0 auto;
  width: 100%;
  max-width: rempx(540);
}

@media (max-width: 428px) {
  .skills {
    margin: 0 auto;
    width: 70%;
    max-width: rempx(350);
  }

  .skill-group {
    margin-bottom: rempx(20);
  }
}

.project-page-title-description {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: rempx(160);
  margin: 0 auto;
}

.ptable {
  padding: rempx(14);
  padding-top: rempx(20);
  font-size: rempx(24);
  font-weight: 600;
}

.ptable-means {
  padding: rempx(14);
  padding-top: rempx(20);
  font-size: rempx(18);
  font-weight: 400;
}

// Images

.img-fluid {
  margin-left: auto;
  margin-right: auto;
}

.m0auto {
  margin: 0 auto;
}

// line

hr {
  border-color: $gray-100;
}

// Padding
.phone-between-padding {
  padding-top: rempx(.05);
}

// ------------- image overlay ------------- //

.card-group > .overlay-card {
  margin-bottom: 0;
}
.overlay-card {
  border: none;
  border-radius: 4px;

  &.-work {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: stretch;
  }

  &.-disabled > .image {
    filter: blur(7.2px);
  }

  > .image {
    width: calc(70%);
    margin-top: calc(10%);
    margin-left: calc(15%);
  }

  > .card-img-overlay {
    padding: 0;
  }
}

.img-overlay {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  // background-color: rgba(black, 0.34);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .logo {
    visibility: hidden;
  }

  &:hover {
    background-color: rgba(black, 0.45);
    > .arrow {
      right: rempx(40);
      opacity: 1;
    }
    > .logo {
      visibility: visible;
      align-self: center;
      justify-content: center;
      max-width: calc(35%);
  
      &.-tall {
        max-width: calc(20%);
      }
    }
  }

  > .placeholder {
    position: absolute;
    top: calc(50% - 3em);
    left: 0;
    right: 0;
    text-align: center;
  }

  > .arrow {
    position: absolute;
    bottom: rempx(30);
    right: rempx(120);
    opacity: 0;
    transition: all 0.3s ease-in-out;

    rect {
      fill: #fff;
    }
  }
}

.personalLogo {
  height: rempx(70);
}

// Buttons

.btn-custom {
  border-radius: 0px;
  letter-spacing: 1px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

// ------------ Project Cards (logos on home) ------------ //

.project-card {
  position: relative;
  height: rempx(220);
  min-width: rempx(100);
  background-color: #131313;
  border-radius: rempx(0);

  margin-left: rempx(5);
  margin-right: rempx(5);
  margin-bottom: rempx(5);
  margin-top: rempx(5);

  &:hover {
    .project-img {
      opacity: 1;
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }

    .project-meta {
      display: flex;
      justify-content: center;
      margin-top: rempx(18)
    }
  }
}

.project-meta {
  display: none;
  margin: 0 auto;

}

.project-card-title {
  font-family: $font-family-unica;
  font-weight: 500;
  font-size: rempx(18);
  color: $white;
  text-transform: uppercase;
  letter-spacing: rempx(2);

}

.project-card-type {
  font-family: $font-family-opensans;
  font-weight: 300;
  font-size: rempx(14);
  color: $white-500;
  margin-top: rempx(98);
  letter-spacing: rempx(1);
}

// logo
.project-img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: .355s;
  opacity: 40%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

}

.first-review {
  margin-top: rempx(0);
}

// Project Pages //

.body-content {
  width: 100%;
  max-width: rempx(1400);
  margin: 0 auto;
  padding: rempx(20);
}

// Image widths

.image-project-page {
  max-height: rempx(600);
}

.image-width-100 {
  width: 95%;
}

.image-width-100-project {
  width: 100%;

  &:first-child {
    margin-right: rempx(30);
  }

  &:nth-child(2) {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.image-width-90 {
  width: 85%;
}

.image-width-75 {
  width: 75%;
}

.image-width-66 {
  width: 66%;
}

.image-width-50 {
  width: 50%;
}

.image-width-40 {
  width: calc(40% - 60px);
  height: auto;

  &:first-child {
    margin-right: rempx(30);
  }
  &:last-child {
    margin-right: rempx(30);
  }
}

.image-width-33 {
  width: 33%;

  &:first-child {
    margin-right: rempx(30);
  }

  &:nth-child(2) {
    margin-right: rempx(30);
  }

  &:last-child {
    margin-right: rempx(30);
  }

}

.image-width-33-chess {
  width: 33%;
}

.image-width-25 {
  width: 25%;

  &:first-child {
    margin-right: rempx(30);
  }

  &:nth-child(2) {
    margin-right: rempx(30);
  }

  &:nth-child(3) {
    margin-right: rempx(30);
  }

  &:last-child {
    margin-right: rempx(30);
  }

}

.image-width-25-singleton {
  width: 25%;

  &:first-child {
    margin-right: rempx(50);
  }

}

.image-width-20 {
  width: 20%;

  &:first-child {
    margin-right: rempx(30);
  }

  &:nth-child(2) {
    margin-right: rempx(30);
  }

  &:nth-child(3) {
    margin-right: rempx(30);
  }

  &:last-child {
    margin-right: rempx(30);
  }

}

.image-width-15 {
  width: 15%;

  &:first-child {
    margin-right: rempx(30);
  }

  &:nth-child(2) {
    margin-right: rempx(30);
  }

  &:nth-child(3) {
    margin-right: rempx(30);
  }

  &:last-child {
    margin-right: rempx(30);
  }
}

// Code Widths

.code-width-40 {
  width: 40%;
}

@media (max-width: 810px) {
  
  .code-width-40 {
    width: 90%;
  }
  
}

@media (max-width: 375px) {
  
  .code-width-40 {
    width: 95%;
  }
  
}

// Text Widths

.text-width-100 {
  width: 30%;
}

.text-width-51 {
  width: 30%;
}

.text-width-50 {
  width: 30%;
}

.text-width-40 {
  width: 40%;
}

.text-width-33 {
  width: 30%;

  &:first-child {
    margin-right: 0;
  }

  &:nth-child(2) {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }

}

.web-margin-r-pivot {
  margin-right: rempx(30);
}

// ---------- Bantam Project Page ---------- //

.bantam-illustrations {
  max-width: rempx(900);
}

.image10 {
  display: block;
}

.image15 {
  display: none;
}

// ---------- Hamburger ---------- //

.hamburger-nav {
  display: none !important;
}

// ---------- Start of Pixels ----------//
// ---------- ---------- ---------- ---------- //
// ---------- ---------- ---------- ---------- //
// ---------- ---------- ---------- ---------- //

@media (max-width: 1360px) {

  .flurish-d-none {
    display: none !important;
  }

  // Bantam L+1

  // Bantam

  .image15 {
    display: none;
  }

  .image-width-100 {
    width: 85%;
  }

  // Widths

  .image-width-51 {
    width: 51%;
  
    &:first-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .image-width-25 {
    width: 25%;
  
    &:first-child {
      margin-right: rempx(30);
    }
  
    &:nth-child(2) {
      margin-right: rempx(30);
    }
  
    &:last-child {
      margin-right: rempx(30);
    }
  
  }

}

@media (max-width: 1190px) {

  .hamburger-nav {
    display: block !important;
  }

  .flex-md-equal > * {
    flex: 1;
  }

  .d-md-flex {
    display: block !important;
  }

  .movie-card {
    margin: rempx(5);
  }

  .image-project-page {
    max-width: rempx(500);
    height: auto;
    width: auto;
    margin-right: rempx(40);
  }

  // Project Images

  .project-page-title-description {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;

  }

  // Widths
  
  // .image-width-75 {
  //   width: 75%;
  // }
  
  // .image-width-66 {
  //   width: 66%;
  // }
  
  // .image-width-33 {
  //   width: 33%;
  // }
  
  // .image-width-25 {
  //   width: 25%;
  // }

  .image-width-100 {
    width: 100%;
  }

  .image-width-100-project {
    width: 100%;
  
    &:first-child {
      margin-right: rempx(30);
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .image-width-66 {
    width: 80%;
  }

  .image-width-51 {
    width: 51%;
  
    &:first-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .text-width-75 {
    width: 51%;
  }

  .text-width-51 {
    width: 30%;

    &:nth-child(2) {
      margin-right: rempx(60);
    }

  }

  .text-width-50 {
    width: 50%;
  }

  //bld double line text
  .text-width-33 {
    width: 33%;

    &:first-child {
      margin-right: rempx(0);
    }

    &:nth-child(2) {
      margin-right: rempx(60);
    }
  }

  .text-width-25 {
    width: 70%;
  }
  

  // end widths

  .pp-ts {
    min-width: rempx(380);
    margin-right: rempx(0);
    margin-bottom: rempx(20);
  }

  .resume-title {
    min-width: rempx(200);
    margin-right: rempx(0);
    margin-bottom: rempx(20);
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-left: rempx(6);
    padding-right: rempx(6);
  }

  .center-bg {
    margin: 0 auto;
  }

  .web-margin-r-pivot {
    margin-right: rempx(30);
  }

  // Bld Images

  // Bantam Images

  .image10 {
    display: block;
  }

  .image15 {
    display: none;
  }

}

// ---------- End of Svgs ---------- //

@media (max-width: 810px) {

  .svglines {
    display: none;
    opacity: 0;
    width: rempx(761);
    height: rempx(263);
    margin: 0 auto;
  }
  
  .svglines_small {
    display: block;
    position: absolute;
    width: rempx(428);
    top: 25%;
    left: 50%;
    margin-left: rempx(-214);
  }

  .arrow-flex {
    position: absolute;
    top: 86%;
  }

  .right-side-nav {
    display: flex;
    justify-content: center;
  }

  .right-side-nav-home {
    display: flex;
    justify-content: center;
  }

  // Bantam Images

  .image10 {
    display: none;
  }

  .image15 {
    display: block;
  }

  // Bld Project Page

  .flex-images-bld {
    flex-wrap: wrap;  
  }

  .pp-title {
    width: 51%;
    max-width: rempx(375);
    min-width: rempx(375);
  }

  .pp-sub-text {
    width: 51%;
    max-width: rempx(375);
    min-width: rempx(320);
  }

  .resume-sub-text {
    width: 51%;
    max-width: rempx(375);
    min-width: rempx(320);
  }

  .tablet-margin-pivot {
    margin-bottom: rempx(30);
  }

  .990-r {
    margin-right: rempx(30);
  }

  // Image Width

  .image-width-100 {
    width: 100%;
  }

  .image-width-100-project {
    width: 100%;
    &:first-child {
      margin-right: 0;
      margin-bottom: rempx(30);
    }
  }

  .image-width-51 {
    width: 51%;
  
    &:first-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .image-width-40 {
    width: 60%;
  
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .image-width-33-chess {
    width: 80%;
  }

  .image-width-25 {
    width: 51%;
  
    &:first-child {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // Text Width

  .text-width-100 {
    width: 63%;
  }

  .text-width-66 {
    width: 70%;
  }

  .text-width-40 {
    width: 50%;
  }

  .text-width-33 {
    width: 51%;
  
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  // bld project overview text  
  .text-width-25 {
    width: 46%;

  }

}

// ---------- Tablet ---------- //

@media (max-width: 574px) {

  .project-page-title-description {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .project-nav {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  // Width

  .image-width-100 {
    width: 100%;
  }

  .image-width-40 {
    width: 100%;
    margin: 0;

    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  
  }

  .image-width-25 {
    width: 70%;
    margin: 0;

    &:first-child {
      margin-right: 0;
    }
  
    &:nth-child(2) {
      margin-right: 0;
    }
  
    &:last-child {
      margin-right: 0;
    }
  }

  .image-width-25-singleton {
    width: 95%;
    margin: 0;
  
    &:first-child {
      margin-right: rempx(0);
      margin-bottom: rempx(30);
    }
  
  }

  .tablet-margin-pivot {
    margin-bottom: rempx(30);
  }

  // Text Width 

  .text-width-100 {
    width: 75%;
  }

  .text-width-33 {
    width: 40%;
  
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }



  // Bantam Images

  .image10 {
    display: none;
  }
  
}

// ---------- iPhone ---------- //

@media (max-width: 428px) {

  h5 {
    font-size: rempx(34);
    font-weight: 300;
  }

  .text-xl {
    font-size: rempx(50);
    width: rempx(100);
  }

  .flex-parent {
    display: block;
  }

  // Nav

  .project-nav {
    margin-bottom: rempx(80);
  }

  .right-side-nav-home {
    margin-right: rempx(30);
  }

  .navbar {
    margin-right: rempx(25);
  }

  .nav-item {
    margin-right: rempx(30);

    &:last-child {
      margin-right: 0;
    }

  }

  // Hero

  .svglines_small {
    display: block;
    position: absolute;
    width: rempx(375);
    top: 42%;
    left: 50%;
    margin-left: rempx(-187);
    margin-top: rempx(-108)
  }

  .top-absolute {
    position: absolute;
    top: 45%;
    margin-top: rempx(-26);
  }

  .bot-absolute {
    position: absolute;
    top: 46%;
  }

  .arrow-flex {
    position: absolute;
    top: 86%;
  }

  .namelines {
    height: rempx(6);
    margin: 0 auto;
    width: 100%;
  }

  .hero-banner {
    margin: 0;
    position: relative;
  }

  // Grid

  .project-card {  
    margin-left: rempx(10);
    margin-right: rempx(10);
    margin-bottom: rempx(5);
    margin-top: rempx(5);
  
  }

  // Projects

  .flurish-horizontal {
    height: rempx(6);
    margin-top: rempx(21);
  }

  .project-page-title-description {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rempx(60);
  }

  .project-page-mid-role {
    text-justify: left;
    margin: 0 auto;
    font-size: rempx(14);
    line-height: rempx(26);
  
    // Padding
    max-width: rempx(375);
    min-width: rempx(375);
    padding-left: rempx(0);
    margin-top: rempx(100);
    margin-bottom: rempx(100);
    opacity: 1;
  }

  .project-double-text {
    max-width: rempx(1080);
    min-width: rempx(370);
    padding-left: rempx(0);
    margin-top: rempx(100);
    margin-bottom: rempx(70);
    opacity: 1;
  }

  .pp-ts {
    margin-left: rempx(-3);
    margin-right: rempx(0);
    min-width: rempx(280);
  }

  .pp-ts-resume {
    margin-left: rempx(-3);
    margin-right: rempx(0);
    width: 80%;
    margin-bottom: rempx(15);
  }

  .pp-title {
    padding-left: rempx(0);
    max-width: rempx(400);
    min-width: rempx(200);
    line-height: rempx(38);
    width: auto;
  }

  .pp-sub-text {
    padding-left: rempx(1);
  }

  .pp-sub-text-bld {
    padding-left: rempx(50);
  }

  .resume-sub-text {
    padding-left: rempx(1);
  }

  // Image Specific

  .image-project-page {
    margin-right: rempx(15);
  }

  .image-width-90 {
    width: 100%;
  }

  .image-width-75 {
    width: 100%;
  }

  .image-width-50 {
    width: 100%;
  }

  .phone-margin-b-pivot {
    margin-bottom: rempx(20);
  }

  // Text widths

  .text-width-100 {
    width: 80%;
  }

  .text-width-75{
    width: 80%;
  }

  .text-width-51 {
    width: 80%;

    &:nth-child(2) {
      margin-right: 0;
    }
  
  }

  .text-width-50 {
    width: 80%;
  }

  .text-width-33 {
    width: 80%;
  
    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .web-margin-r-pivot {
    margin-right: 0;
  }

  // Bantam Images

  .image10 {
    display: none;
  }

  .image15 {
    display: flex;
  }

  .movie-card {
    position: relative;
    height: rempx(420);
    min-width: rempx(100);
    background-color: $black;
    border-radius: rempx(0);
  
    &:hover {
      .movie-img {
        opacity: .8;
      }
    }
  }

  .review-card {
    height: rempx(460);
    margin-left: 0;
    margin-right: 0;
    background-color: $sf-bg-dark;
  }

  .row {
    margin-left: rempx(0);
    margin-right: rempx(0);
  }

  // Google

  .mb-mobile-google {
    margin-bottom: rempx(100);
  }

}